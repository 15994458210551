<template>
  <b-card :title="isEditMode ? 'Edit Option' : 'Add Option'" class="m-2">
    <div>
      <b-card v-if="loading" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </b-card>

      <b-card v-if="saving" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </b-card>

      <b-form @submit="onSubmit" v-if="!loading && !saving">
        <b-card title="Option Details" class="mb-2">
          <b-form-group
            id="input-group-label"
            label="Label:"
            label-for="input-label"
          >
            <b-form-input
              id="input-label"
              required
              placeholder="Enter label."
              v-model="option.label"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-description"
            label="Description:"
            label-for="input-description"
          >
            <b-form-input
              id="input-description"
              placeholder="Enter description."
              v-model="option.description"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-audio-url"
            label="Audio Url:"
            label-for="input-audio-url"
          >
            <b-form-input
              id="input-audio-url"
              placeholder="Enter audio url."
              readonly
              :value="option.audio_url || 'No audio'"
            ></b-form-input>
            <div class="eu-error mt-2" v-if="uploadError">
              {{uploadError}}
            </div>
            <b-button size="sm" class="mt-1" :disabled="uploading" @click="openUploadFile()">Upload</b-button>
            <span class="ml-2 d-inline-block" v-if="uploading"><b-spinner variant="primary" small></b-spinner></span>
          </b-form-group>
          <input type="file" id="option-upload-file-input" @change="uploadFile($event)" accept=".mp3" style="display: none;"/>
        </b-card>

        <b-button type="submit" variant="primary" class="mr-1" size="sm">
          <b-icon icon="check-circle-fill" aria-hidden="true"></b-icon>
          {{ isEditMode ? 'Save Changes' : 'Add' }}
        </b-button>

        <b-button @click="onCancel" variant="danger" size="sm">
          <b-icon icon="x-circle-fill" aria-hidden="true"></b-icon>
          Cancel
        </b-button>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import API from "@/api";

export default {
  name: "AdminOptionEdit",
  data: () => ({
    isEditMode: false,
    option: {},
    loading: false,
    saving: false,
    uploading: false,
    uploadError: null,
  }),
  created() {
    this.isEditMode = !!this.$route.params.id;
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  methods: {
    uploadFile(event) {
      this.uploading = true;
      let reader = new FileReader();
      reader.onload = async (e) => {
        let bytes = new Uint8Array(e.target.result);
        let len = e.target.result.byteLength;
        let binary = "";
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const base64String = window.btoa(binary);
        try {
          this.option.audio_url = await API.uploadFile(
            base64String,
            event.target.files[0].type
          );
          this.uploadError = '';
        } catch(e) {
          this.uploadError = 'Read size limit exceeded. (Max size is 3M)';
        }
        this.uploading = false;
        document.getElementById('option-upload-file-input').value = "";
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    },
    openUploadFile() {
      document.getElementById('option-upload-file-input').click();
    },
    onCancel() {
      this.$router.push("/admin/options");
    },
    async onSubmit(event) {
      event.preventDefault();

      this.saving = true;

      if (!this.option._id) {
        await API.insert("options", this.option).catch((error) => {
          console.log(error);
        });
      } else {
        let filter = {
          _id: this.option._id,
        };

        let updateData = { ...this.option };
        delete updateData._id;
        let update = { $set: updateData };
        let result = await API.update("options", filter, update).catch(
          (error) => {
            console.log(error);
          }
        );
        console.log(result);
      }
      await API.refresh();
      this.$router.push("/admin/options");
    },
    async fetchData() {
      this.loading = true;

      const BSON = require("bson");
      const realmApp = API.realmApp;

      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("options");

      let optionId = new BSON.ObjectID(this.$route.params.id);
      let filter = {
        _id: optionId,
      };

      const result = await mongoCollection.findOne(filter);

      if (result != null) {
        this.option = result;
      }

      this.loading = false;
    },
  },
};
</script>
