const QUESTION_TYPES = {
  SLIDER_QUESTION: 'SliderQuestion',
  THREE_SLIDER_QUESTION: 'ThreeSliderQuestion',
  SIX_SLIDER_QUESTION: 'SixSliderQuestion',
  VOICE_QUESTION: 'VoiceQuestion',
  INFORMATION_QUESTION: 'InformationQuestion',
  MEDITATION_QUESTION: 'MeditationQuestion',
  PORTAL_QUESTION: 'PortalQuestion',
  VALUE_SELECTOR_QUESTION: 'ValueSelectorQuestion',
}

const ENVIRONMENT_OPTIONS = [
  { value: '', text: '' },
  { value: 'beach1', text: 'Beach 1' },
  { value: 'beach2', text: 'Beach 2' },
  { value: 'beach3', text: 'Beach 3' },
  { value: 'river1', text: 'River 1' },
  { value: 'star1', text: 'Star 1' },
]

const TYPE_OPTIONS = {
  SliderQuestion: { value: 'SliderQuestion', text: 'SliderQuestion' },
  ThreeSliderQuestion: { value: 'ThreeSliderQuestion', text: 'ThreeSliderQuestion' },
  SixSliderQuestion: { value: 'SixSliderQuestion', text: 'SixSliderQuestion' },
  VoiceQuestion: { value: 'VoiceQuestion', text: 'VoiceQuestion' },
  InformationQuestion: { value: 'InformationQuestion', text: 'InformationQuestion' },
  MeditationQuestion: { value: 'MeditationQuestion', text: 'MeditationQuestion' },
  PortalQuestion: { value: 'PortalQuestion', text: 'PortalQuestion' },
  ValueSelectorQuestion: { value: 'ValueSelectorQuestion', text: 'ValueSelectorQuestion' },
  OrderQuestion: { value: 'OrderQuestion', text: 'OrderQuestion' }
}
export default {
  QUESTION_TYPES,
  ENVIRONMENT_OPTIONS,
  TYPE_OPTIONS
}