<template>
  <b-card header="Options">
    <b-table
      hover
      small
      outlined
      stacked="sm"
      :items="options"
      select-mode="multi"
      :busy="loading"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      ref="selectableTable"
      selectable
      @row-selected="onRowSelected"
    >
      <!-- Example scoped slot for select state illustrative purposes -->
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template #cell(position)="row">
        <template v-if="row.rowSelected">
          <b-form-input
            id="input-position"
            type="number"
            size="sm"
            placeholder="Enter position."
            v-model="row.item.position"
          ></b-form-input>
        </template>
        <template v-else>
        </template>
      </template>
      <template v-slot:table-busy>
        <div class="text-center my-5">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="optionsTotal"
      :per-page="perPage"
      align="center"
    ></b-pagination>
  </b-card>
</template>

<script>
import API from "@/api";
export default {
  props: ["questionId"],
  name: "OptionsCard",
  data() {
    return {
      loading: true,
      fields: [
        { key: "selected", sortable: false },
        { key: "label", sortable: true },
        { key: "description", sortable: true },
        { key: "position", sortable: true }
      ],
      currentPage: 1,
      perPage: 10,
      options: [],
      selected: []
    };
  },
  computed: {
    optionsTotal() {
      return this.options.length;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    async fetchData() {
      const realmApp = API.realmApp;
      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const optionsCollection = mongo.db("eunoe").collection("options");
      const optionResults = await optionsCollection.find({});

      const questionOptionsCollection = mongo.db("eunoe").collection("question_options");
      const query = this.questionId ? {questionId: this.questionId} : {};
      const selectedQuestionOptions = await questionOptionsCollection.find(query);
      const selectedOptionIds = selectedQuestionOptions.map(qo => qo.optionId);
      this.options = optionResults;
      setTimeout(() => {
        this.options.forEach((o, index) => {
          if (selectedOptionIds.includes(o._id.toString())) {
            this.$refs.selectableTable.selectRow(index);
            o.position = selectedQuestionOptions[index].position;
          }
        });
      })

      this.loading = false;
    },
  },
};
</script>