<template>
  <b-card title="Admin Dashboard" class="m-2">
    <questions-card/>
  </b-card>
</template>

<script>
import QuestionsCard from '@/components/QuestionsCard.vue';
export default {
  name: "Admin",
  components: {
    QuestionsCard
  },
};
</script>