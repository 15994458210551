<template>
  <b-card :title="isEditMode ? 'Edit Question' : 'Add Question'" class="m-2">
    <div>
      <b-card v-if="loading" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </b-card>

      <b-card v-if="saving" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </b-card>

      <b-form @submit="onSubmit" v-show="!loading && !saving">
        <b-card title="Question Details" class="mb-2">
          <b-form-group
            id="input-group-module"
            label="Module:"
            label-for="input-module"
          >
            <b-form-select
              v-model="question.moduleId"
              id="input-module"
              placeholder="Select module"
              @change="onModuleChange()"
              :options="module_options">
            </b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-text"
            label="Text:"
            label-for="input-text"
          >
            <b-form-input
              id="input-text"
              required
              placeholder="Enter text."
              v-model="question.text"
              :state="textState"
            ></b-form-input>
            <b-form-invalid-feedback>
              Max length is 220 characters.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-type"
            label="Type:"
            label-for="input-type"
          >
            <b-form-select
              id="input-type"
              v-model="question.type"
              placeholder="Select type."
              required
              @change="onTypeChange()"
              :options="Object.values(TYPE_OPTIONS)">
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-recording-length"
            label="Recording Length:"
            label-for="input-recording-length"
            v-if="question.type === TYPE_OPTIONS.VoiceQuestion.value"
          >
            <b-form-input
              id="input-recording-length"
              placeholder="Enter recording length."
              min="0"
              type="number"
              v-model="question.recording_length"
            ></b-form-input>
          </b-form-group>
          
          <b-form-group
            id="input-group-related-question"
            :label="`Related to question: ${fetching ? '(Loading...)' : ''}`"
            label-for="input-related-question"
            v-if="possibleRelatedQuestions && (question.type === TYPE_OPTIONS.SliderQuestion.value || question.type === TYPE_OPTIONS.ThreeSliderQuestion.value || question.type === TYPE_OPTIONS.SixSliderQuestion.value)"
          >
            <b-form-select
              id="input-related-question"
              v-model="question.relatedQuestionId"
              placeholder="Select related question."
              :disabled="fetching"
              :options="possibleRelatedQuestions">
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-order"
            label="Order:"
            label-for="input-order"
          >
            <b-form-input
              id="input-order"
              type="number"
              placeholder="Enter order number."
              v-model="question.order"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-environment"
            label="Environment:"
            label-for="input-environment"
          >
            <b-form-select
              id="input-environment"
              v-model="question.environment"
              placeholder="Enter environment."
              :options="ENVIRONMENT_OPTIONS">
            </b-form-select>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-audio"
            label="Audio:"
            label-for="input-audio"
          >
            <b-form-input
              id="input-audio"
              placeholder="Enter audio."
              v-model="question.audio"
            ></b-form-input>
          </b-form-group> -->

          <b-form-group
            id="input-group-audio-url"
            label="Audio Url:"
            label-for="input-audio-url"
          >
            <b-form-input
              id="input-audio-url"
              placeholder="Enter audio url."
              :value="question.audio_url || 'No audio'"
            ></b-form-input>
            <div class="eu-error mt-2" v-if="uploadError">
              {{uploadError}}
            </div>
            <b-button :disabled="uploading" size="sm" class="mt-1" @click="openUploadFile()">Upload</b-button>
            <span class="ml-2 d-inline-block" v-if="uploading"><b-spinner variant="primary" small></b-spinner></span>
          </b-form-group>
          <input type="file" id="upload-file-input" @change="uploadFile($event)" accept=".mp3" style="display: none;"/>

          <b-form-group
            id="input-group-response-labels"
            label="Response Labels:"
            label-for="input-response-labels"
          >
            <b-form-tags
              id="input-response-labels"
              placeholder="Enter response labels."
              v-model="question.response_labels"
            ></b-form-tags>
          </b-form-group>

          <b-form-group
            id="input-group-options"
            label="Options:"
            label-for="input-options"
          >
            <b-form-tags
              id="input-options"
              placeholder="Enter options."
              v-model="question.options"
              :state="optionsState"
            ></b-form-tags>
            <b-form-invalid-feedback>
              {{optionsInvalidFeedback}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-checkbox
            id="checkbox-active"
            v-model="question.active"
            name="checkbox-active"
          >
            Active
          </b-form-checkbox>

          <b-form-checkbox
            id="checkbox-dashboard"
            v-model="question.dashboard"
            name="checkbox-dashboard"
            class="mb-3"
          >
            Visible on dashboard
          </b-form-checkbox>
          <options-card ref="optionsCard" :questionId="question && question._id ? question._id.toString() : undefined"/>
        </b-card>

        <b-button type="submit" variant="primary" class="mr-1" size="sm" :disabled="textState === false || optionsState === false">
          <b-icon icon="check-circle-fill" aria-hidden="true"></b-icon>
          {{ isEditMode ? 'Save Changes' : 'Add' }}
        </b-button>

        <b-button @click="$router.go(-1)" variant="danger" size="sm">
          <b-icon icon="x-circle-fill" aria-hidden="true"></b-icon>
          Cancel
        </b-button>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import API from "@/api";
import OptionsCard from '../../components/sections/OptionsCard.vue';
import constants from "@/constants";

export default {
  components: { OptionsCard },
  name: "AdminQuestionEdit",
  computed: {
    textState() {
      if (!this.question.text) return null;
      return this.question.text.length < 220 ? true : false;
    },
    optionsInvalidFeedback() {
      if (this.question.type === this.TYPE_OPTIONS.ThreeSliderQuestion.value) {
        return `${this.question.type} question should have 3 options.`;
      } else if (this.question.type === this.TYPE_OPTIONS.SixSliderQuestion.value) {
        return `${this.question.type} question should have 6 options.`;
      } else if (this.question.type === this.TYPE_OPTIONS.PortalQuestion.value) {
        return `${this.question.type} question should have options`;
      }
      return '';
    },
    optionsState() {
      if (this.question.type === constants.TYPE_OPTIONS.ThreeSliderQuestion.value) {
        return this.question.options.length === 3 ? true : false;
      } else if (this.question.type === constants.TYPE_OPTIONS.SixSliderQuestion.value) {
        return this.question.options.length === 6 ? true : false;
      } else if (this.question.type === constants.TYPE_OPTIONS.PortalQuestion.value) {
        return this.question.options.length > 0 ? true : false;
      }
      return null;
    }
  },
  data: () => ({
    isEditMode: false,
    question: {
      order: 0,
      active: true,
      moduleId: '',
      options: [],
      response_labels: [],
      dashboard: true,
      recording_length: 0,
      relatedQuestionId: '',
    },
    module_options: [],
    ENVIRONMENT_OPTIONS: constants.ENVIRONMENT_OPTIONS,
    TYPE_OPTIONS: constants.TYPE_OPTIONS,
    loading: false,
    saving: false,
    fetching: false,
    uploading: false,
    uploadedUrl: '',
    uploadError: null,
    possibleRelatedQuestions: [],
  }),
  created() {
    this.isEditMode = !!this.$route.params.id;
    this.question.moduleId = this.$route.params.moduleId || '';
    this.fetchData();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from;
    })
  },
  methods: {
    uploadFile(event) {
      this.uploading = true;
      let reader = new FileReader();
      reader.onload = async (e) => {
        let bytes = new Uint8Array(e.target.result);
        let len = e.target.result.byteLength;
        let binary = "";
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const base64String = window.btoa(binary);
        try {
          this.question.audio_url = await API.uploadFile(
            base64String,
            event.target.files[0].type
          );
          this.uploadError = '';
        } catch(e) {
          this.uploadError = 'Read size limit exceeded. (Max size is 3M)';
        }
        this.uploading = false;
        document.getElementById('upload-file-input').value = "";
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    },
    openUploadFile() {
      document.getElementById('upload-file-input').click();
    },
    async onSubmit(event) {
      const BSON = require("bson");
      event.preventDefault();

      this.saving = true;

      if (!this.question._id) {
        console.log("Inserting new question.", this.question);
        this.question.order = parseInt(this.question.order, 10);
        this.question.recording_length = this.question.type === this.TYPE_OPTIONS.VoiceQuestion.value && this.question.recording_length ?
          parseInt(this.question.recording_length, 10) :
          0;

        this.question.lastUpdated = new Date().getTime();
        let result = await API.insert("questions", this.question).catch((error) => {
          console.log(error);
        });
        this.question._id = result.insertedId;
      } else {
        console.log("Updating question.");
        console.log(this.question);

        let filter = {
          _id: this.question._id,
        };

        let updateData = { ...this.question };
        updateData.order = parseInt(updateData.order, 10);
        updateData.recording_length = this.question.type === this.TYPE_OPTIONS.VoiceQuestion.value && this.question.recording_length ?
          parseInt(this.question.recording_length, 10) :
          0;
        delete updateData._id;
        updateData.lastUpdated = new Date().getTime();

        console.log(updateData);

        let update = { $set: updateData };
        let result = await API.update("questions", filter, update).catch(
          (error) => {
            console.log(error);
          }
        );
        console.log(result);
      }

      // Update of related question id.
      if (this.question.relatedQuestionId) {
        let filter = {_id: new BSON.ObjectID(this.question.relatedQuestionId)}
        let update = { $set: {"relatedQuestionId" : this.question._id.toString()} };
        await API.update("questions", filter, update).catch((error) => {
          console.log(error);
        })
      }

      // update question options
      const realmApp = API.realmApp;
      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const questionOptionsCollection = mongo.db("eunoe").collection("question_options");
      const query = {questionId: this.question._id.toString()};
      await questionOptionsCollection.deleteMany(query);

      if (this.$refs.optionsCard.selected.length > 0) {
        await questionOptionsCollection.insertMany(
          this.$refs.optionsCard.selected.map(s => ({questionId: this.question._id.toString(), optionId: s._id.toString(), position: s.position ? parseInt(s.position,  10) : 0}))
        );
      }
      // end of update question options

      await API.refresh();

      if (this.from && this.from.name === "AdminModuleEdit") {
        this.$router.push(`/admin/modules/edit/${this.question.moduleId}`);
      } else {
        this.$router.push("/admin/questions");
      }
    },
    isValidRelatedQuestionType() {
      return (this.question.type === this.TYPE_OPTIONS.SliderQuestion.value ||
        this.question.type === this.TYPE_OPTIONS.ThreeSliderQuestion.value ||
        this.question.type === this.TYPE_OPTIONS.SixSliderQuestion.value);
    },
    async refreshPossibleRelatedQuestions() {
      if (this.question.moduleId && this.isValidRelatedQuestionType()) {
        this.fetching = true;
        const realmApp = API.realmApp;
        const mongo = realmApp.services.mongodb("mongodb-atlas");
        const questionsCollection = mongo.db("eunoe").collection("questions");
        const questions = await questionsCollection.find({moduleId: this.question.moduleId});
        let relatedQuestionsData = questions.filter(q => q.type === this.question.type);
        relatedQuestionsData = relatedQuestionsData.filter(q => !this.question._id || this.question._id.toString() !== q._id.toString());
        this.possibleRelatedQuestions = relatedQuestionsData.map(item => ({text: item.text, value: item._id.toString()}))
        this.possibleRelatedQuestions.unshift({text: '', value: ''});
        this.fetching = false;
      }
    },
    onModuleChange() {
      this.refreshPossibleRelatedQuestions();
      this.question.relatedQuestionId = '';
    },
    onTypeChange() {
      this.refreshPossibleRelatedQuestions();
      this.question.relatedQuestionId = '';
    },
    async fetchData() {
      const BSON = require("bson");
      const realmApp = API.realmApp;
      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const questionsCollection = mongo.db("eunoe").collection("questions");
      const modulesCollection = mongo.db("eunoe").collection("modules");
      this.loading = true;

      const modules = await modulesCollection.find();
      this.module_options = modules.map(module => ({value: module._id.toString(), text: `${module.title} (${module._id.toString()})`}));
      this.module_options.unshift({value: '', text: ''});

      if (this.isEditMode) {
        let questionId = new BSON.ObjectID(this.$route.params.id);
        let filter = {
          _id: questionId,
        };

        const result = await questionsCollection.findOne(filter);
        this.question = result;
      }
      this.refreshPossibleRelatedQuestions();

      this.loading = false;
    },
  },
};
</script>
