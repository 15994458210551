<template>
  <b-card title="Admin Dashboard" class="m-2">
    <b-card header="Options">
      <b-form inline>
        <b-input-group class="mb-2">
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''" class="mr-2"
              >Clear</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <b-button
          :to="{ name: 'AdminOptionAdd' }"
          variant="primary"
          class="mb-2 mr-2"
          >Create
        </b-button>
      </b-form>

      <b-table
        hover
        small
        outlined
        stacked="sm"
        :items="options"
        :busy="loading"
        :fields="fields"
        :filter="filter"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template v-slot:cell(actions)="row">
          <b-button @click="deleteOptionConfirm(row.item)" class="mr-1">
            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
          </b-button>
          <b-button
            :to="{ name: 'AdminOptionEdit', params: { id: row.item._id } }"
          >
            <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template v-slot:table-busy>
          <div class="text-center my-5">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="optionsTotal"
        :per-page="perPage"
        align="center"
      ></b-pagination>
    </b-card>
  </b-card>
</template>

<script>
import API from "@/api";
export default {
  name: "AdminOptions",
  data() {
    return {
      loading: true,
      filter: "",
      fields: [
        { key: "label", sortable: true },
        { key: "description", sortable: true },
        { key: "actions", sortable: false }
      ],
      currentPage: 1,
      perPage: 10,
      options: [],
    };
  },
  computed: {
    optionsTotal() {
      return this.options.length;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    deleteOptionConfirm(option) {
      let message = "Are you sure you want to delete option " + option._id + "?";

      this.$bvModal.msgBoxConfirm(message).then(async (value) => {
        if (value) {
          this.deleteOption(option);
        } else {
          // Delete cancelled.
        }
      });
    },
    async deleteOption(option) {
      this.loading = true;

      let filter = {
        _id: option._id,
      };
      let result = await API.delete("options", filter);
      console.log(result);

      this.options = [];
      this.fetchData();
    },
    async fetchData() {
      const realmApp = API.realmApp;
      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("options");
      const optionResults = await mongoCollection.find();
      this.options = optionResults;
      this.loading = false;
    },
  },
};
</script>